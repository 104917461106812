<template>
  <div>
    <PageHeader text="Страница создания проекта" />
    <ProjectForm />
  </div>
</template>

<script>
import ProjectForm from '@/components/forms/ProjectForm.vue'
import PageHeader from '@/components/common/PageHeader.vue'

export default {
  components: {
    ProjectForm,
    PageHeader,
  },
}
</script>
